import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import {
  Box,
  styled,
  Stepper as St,
  Step,
  useTheme,
  StepLabel,
  useMediaQuery,
  StepContent,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { setTitleGral } from "../../store/actions";
import { UseImage } from "../../components/UseImage";
import { RegistrationEditionTemplate } from "./RegistrationEditionTemplate";
import { Icon } from "@iconify/react";
import DynamicInformationTemplate from "./DynamicInformationTemplate";
import ConfigFirmDragTemplates from "./ConfigFirmDragTemplates";

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#FFFFFF",
  border: "1px solid #C5C8CD",
  zIndex: 1,
  color: "#000",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#FFFFFF",
    border: "1px solid #C20E30",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#FFFFFF",
    border: "1px solid #C20E30",
  }),
  [theme.breakpoints.down("sm")]: {
    width: 25,
    height: 25,
  },
}));

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
    left: "calc(-40% + 16px)",
    right: "calc(60% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#FFB8B8",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#FFB8B8",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#C5C8CD",
    borderTopWidth: 1,
    borderRadius: 1,
  },
}));

const Stepper = styled(St)(({ theme }) => ({
  margin: "20px auto",
  marginBottom: 20,
  maxWidth: "80%",
  "& .Mui-active": {
    color: theme.palette.primary.main,
  },
  "& .MuiStepLabel-label": {
    fontSize: 14,
  },
  [theme.breakpoints.down("sm")]: {
    transform: "translateX(-5px)",
    margin: "5px auto",
    marginBottom: 30,
  },
  [theme.breakpoints.down("md")]: {
    ".MuiStepLabel-root": {
      alignItems: "flex-start",
    },
    "& .MuiStepLabel-label": {
      textAlign: "left",
    },
  },
  [theme.breakpoints.down("sm")]: {
    margin: "10px auto",
    ".MuiStepLabel-root": {
      alignItems: "flex-start",
    },
    "& .MuiStepLabel-label": {
      textAlign: "left",
      fontSize: 12,
    },
  },
}));

function ColorlibStepIcon(props) {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { active, completed, className } = props;

  const icons = {
    1: <Icon icon="solar:document-linear" fontSize={28} color="#C20E30" />,
    2:
      active || completed ? (
        <Icon icon="solar:document-add-linear" fontSize={28} color="#C20E30" />
      ) : (
        <Icon icon="solar:document-add-linear" fontSize={28} color="#848484" />
      ),
    3:
      active || completed ? (
        <UseImage
          type="Image"
          src="IconPreFirma.svg"
          width={mobileScreen ? 12.25 : 25}
          height={mobileScreen ? 12.25 : 25}
        />
      ) : (
        <UseImage
          type="Image"
          src="IconPreFirmaDisabled.svg"
          width={mobileScreen ? 12.25 : 25}
          height={mobileScreen ? 12.25 : 25}
        />
      ),
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export const NewTemplateSteps = () => {
  const stateRedux = useSelector((state) => state);
  const [activeStep, setActiveStep] = useState(0);
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const [backItem, setBackItem] = useState(false);

  const formMethods = useForm({ mode: "onChange" });

  useEffect(() => {
    dispatch(setTitleGral(getTitle()));
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNextDouble = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 2);
  };

  const handleBackDouble = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 2);
  };

  const handleStep = (step) => {
    setActiveStep(step);
  };

  const steps = [
    {
      label: "Información",
    },
    {
      label: "Configuración",
    },
    {
      label: "Zona de firma",
    },
  ];

  const renderComponent = () => {
    if (activeStep === 0) {
      return (
        <RegistrationEditionTemplate
          handleNextStep={handleNext}
          handleBackStep={handleBack}
          handleSetStep={handleStep}
          setBackItem={setBackItem}
          handleNextDouble={handleNextDouble}
        />
      );
    } else if (activeStep === 1) {
      return (
        <DynamicInformationTemplate
          handleNextStep={handleNext}
          handleBackStep={handleBack}
          handleSetStep={handleStep}
          setBackItem={setBackItem}
          backItem={backItem}
        />
      );
    } else if (activeStep === 2) {
      return (
        <ConfigFirmDragTemplates
          handleNextStep={handleNext}
          handleBackStep={handleBack}
          handleSetStep={handleStep}
          setBackItem={setBackItem}
          handleBackDouble={handleBackDouble}
        />
      );
    }
  };

  const getTitle = () => {
    switch (activeStep) {
      case 0:
        return "Información";
      case 1:
        return "Configuración";
      case 2:
        return "Zona de firma";
      default:
        return "Creación de la solicitud";
    }
  };

  return (
    <FormProvider {...formMethods} theme={theme}>
      <Box
        sx={{
          width: "100%",
          maxWidth: "95%",
          margin: "0 auto",
          [theme.breakpoints.down("sm")]: {
            maxWidth: "100% !important",
          },
        }}
      >
        <Stepper
          alternativeLabel={mobileScreen ? false : true}
          connector={<QontoConnector />}
          activeStep={activeStep}
          orientation={mobileScreen ? "vertical" : "horizontal"}
        >
          {steps.map((step, index) => {
            return (
              <Step
                // onClick={() => setActiveStep(index)}
                key={step.label}
                sx={{
                  [theme.breakpoints.down("sm")]: {
                    width: "100% !important",
                  },
                }}
              >
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {index === activeStep ? (
                    <spam style={{ fontWeight: 650 }}>{step.label}</spam>
                  ) : (
                    step.label
                  )}
                </StepLabel>
                {mobileScreen && (
                  <StepContent sx={{ paddingLeft: 2 }}>
                    {renderComponent()}
                  </StepContent>
                )}
              </Step>
            );
          })}
        </Stepper>
        {!mobileScreen && renderComponent()}
      </Box>
    </FormProvider>
  );
};
